import * as React from 'react'
import Seo from '../../components/SEO/SEO.js'
import Touchable from '../../components/Touchable/Touchable.js'
import * as styles from './404.module.css'

const NotFoundPage = () => (
  <div className={styles.root}>
    <div className={styles.inner}>
      <div className={styles.circle} />
      <div className={styles.circle} />
      <div className={styles.circle} />
      <Touchable to="/" className={styles.title}>
        <div className={styles.ohsnap}>
          <span>Oh snap!</span>
          <span>That's a 404</span>
          <span>Not Found</span>
        </div>
        <div className={styles.pierre}>pierre.cx</div>
      </Touchable>
    </div>
  </div>
)

export default NotFoundPage

export const Head = () => <Seo title="404: Not found" />
