import React from 'react'
import { useSiteMetadata } from '../../hooks/useSiteMetadata.js'

const SEO = ({ title, description, pathname, children }) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    keywords,
    author,
    siteUrl,
    twitterUsername,
  } = useSiteMetadata()

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    keywords: keywords.join(', '),
    author,
    logo: `${siteUrl}${require('../../images/logo.png').default}`,
    twitterUsername,
    url: `${siteUrl}${pathname || ``}`,
  }

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords} />
      <meta name="author" content={seo.author} />
      <meta name="image" content={seo.logo} />

      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:type" content={'website'} />
      <meta property="og:image" content={seo.logo} />

      <meta name="twitter:card" content={'summary'} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:creator" content={seo.twitterUsername} />
      <meta name="twitter:image" content={seo.logo} />
      <meta name="twitter:url" content={seo.url} />

      {children}
    </>
  )
}

export default SEO
